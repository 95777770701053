/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as Moo from "moo";

export enum TOKEN_KIND {
	WHITESPACE = "ws",
	NEWLINE = "nl",
	COMMENT_SINGLINE = "comment",
	COMMENT_OPEN = "commentOpen",
	COMMENT_CLOSE = "commentClose",
	LOWER_THAN_EQL = "lte",
	LOWER_THAN = "lt",
	GREATER_THAN_EQL = "gte",
	GREATER_THAN = "gt",
	EQUAL = "eq",
	NOT_EQUAL = "neq",
	NOT = "not",
	PARAN_OPEN = "paranOpen",
	PARAN_CLOSE = "paranClose",
	COMMA = "comma",
	SPREAD = "spread",
	DOT = "dot",
	BRACKET_OPEN = "bracketOpen",
	BRACKET_CLOSE = "bracketClose",
	BRACE_OPEN = "braceOpen",
	BRACE_CLOSE = "braceClose",
	ASSIGNMENT = "assignment",
	PLUS = "plus",
	MINUS = "minus",
	MULTIPLY = "multiply",
	DIVDE = "div",
	MODULO = "modulo",
	COLON = "colon",
	SEMICOLON = "semicolon",
	QUESTIONMARK = "questionmark",
	OR = "or",
	PIPE = "pipe",
	AND = "and",
	AMP = "amp",
	AT = "at",
	TILDA = "tilda",
	STRING_TEMPLATE_BEGIN = "stringTemplateBegin",
	STRING_TEMPLATE_END = "stringTemplateEnd",
	STRING_LITERAL = "stringLiteral",
	NUMBER_LITERAL = "numberLiteral",
	IDENTIFIER_LITERAL = "identifierLiteral",
	IDENTIFIER = "identifier",
	CHAR = "char",
	ESCAPE = "escape",
	INTERPOLATION_BEGIN = "interpolationBegin",
	INTERPOLATION_DISABLE = "interpolationDisable",
	TRUE = "true",
	FALSE = "false",
	NULL = "null",
}

export enum EXP_PARSE_ERROR_NAME {
	UNEXPECTED_TOKEN = "unexpectedToken",
	UNEXPECTED_EOF = "unexpectedEof",
	DUPLICATE_KEY = "duplicateKey"
}

/**
 * Keywords
 */
export const keywords = {
	[TOKEN_KIND.TRUE]: [ "true", "TRUE" ],
	[TOKEN_KIND.FALSE]: [ "false", "FALSE" ],
	[TOKEN_KIND.NULL]: [ "null", "NULL" ],
	[TOKEN_KIND.AND]: [ "and", "AND" ],
	[TOKEN_KIND.OR]: [ "or", "OR" ],
	[TOKEN_KIND.NOT]: [ "not", "NOT" ]
};

const lexerState_Main = {
	[TOKEN_KIND.WHITESPACE]: /[ \t]+/,
	[TOKEN_KIND.NEWLINE]: { match: /\r?\n/, lineBreaks: true },
	[TOKEN_KIND.COMMENT_SINGLINE]: /\/\/.*?$/,
	[TOKEN_KIND.COMMENT_OPEN]: /\/\*/,
	[TOKEN_KIND.COMMENT_CLOSE]: /\*\//,
	[TOKEN_KIND.LOWER_THAN_EQL]: "<=",
	[TOKEN_KIND.LOWER_THAN]: "<",
	[TOKEN_KIND.GREATER_THAN_EQL]: ">=",
	[TOKEN_KIND.GREATER_THAN]: ">",
	[TOKEN_KIND.EQUAL]: "==",
	[TOKEN_KIND.NOT_EQUAL]: "!=",
	[TOKEN_KIND.NOT]: "!",
	[TOKEN_KIND.PARAN_OPEN]: "(",
	[TOKEN_KIND.PARAN_CLOSE]: ")",
	[TOKEN_KIND.COMMA]: ",",
	[TOKEN_KIND.SPREAD]: "...",
	[TOKEN_KIND.DOT]: ".",
	[TOKEN_KIND.BRACKET_OPEN]: "[",
	[TOKEN_KIND.BRACKET_CLOSE]: "]",
	[TOKEN_KIND.BRACE_OPEN]: {
		match: "{",
		push: "main"
	},
	[TOKEN_KIND.BRACE_CLOSE]: {
		match: "}",
		pop: 1
	},
	[TOKEN_KIND.ASSIGNMENT]: "=",
	[TOKEN_KIND.MULTIPLY]: "*",
	[TOKEN_KIND.DIVDE]: "/",
	[TOKEN_KIND.MODULO]: "%",
	[TOKEN_KIND.COLON]: ":",
	[TOKEN_KIND.SEMICOLON]: ";",
	[TOKEN_KIND.QUESTIONMARK]: "?",
	[TOKEN_KIND.OR]: "||",
	[TOKEN_KIND.PIPE]: "|",
	[TOKEN_KIND.AND]: "&&",
	[TOKEN_KIND.AMP]: "&",
	[TOKEN_KIND.AT]: "@",
	[TOKEN_KIND.TILDA]: "~",
	[TOKEN_KIND.STRING_TEMPLATE_BEGIN]: {
		match: "`",
		push: "string_template"
	},
	[TOKEN_KIND.STRING_LITERAL]: {
		match: /"(?:[^\n\\"]|\\["\\ntbfr])*"/,
		value: (s) => JSON.parse(s)
	},
	[TOKEN_KIND.NUMBER_LITERAL]: {
		match: /[0-9]+(?:\.[0-9]+)?/,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: (s) => Number(s) as any
	},
	[TOKEN_KIND.PLUS]: "+",
	[TOKEN_KIND.MINUS]: "-",
	[TOKEN_KIND.IDENTIFIER_LITERAL]: {
		match: /'(?:[^\\']|\\['\\])*'/,
		value: (s) => s.substr(1, s.length - 2)
	},
	[TOKEN_KIND.IDENTIFIER]: {
		match: /[a-zA-Z_$][a-zA-Z_0-9$]*/,
		type: Moo.keywords(keywords)
	},
	[TOKEN_KIND.CHAR]: {
		match: /./
	}
} as Moo.Rules;

const lexerState_StringTemplate = {
	[TOKEN_KIND.INTERPOLATION_DISABLE]: {
		match: "#![disable-interpolation]",
		next: "literal_string"
	},
	[TOKEN_KIND.ESCAPE]: {
		match: /\\/,
		push: "escape"
	},
	[TOKEN_KIND.INTERPOLATION_BEGIN]: {
		match: "${",
		push: "main"
	},
	[TOKEN_KIND.STRING_TEMPLATE_END]: {
		match: "`",
		pop: 1
	},
	[TOKEN_KIND.CHAR]: {
		match: /(?:[^$`\\]|\\?\$(?!\{))+/,
		lineBreaks: true
	}
} as Moo.Rules;

const lexerState_Escape = {
	[TOKEN_KIND.CHAR]: {
		match: /.{1}/,
		pop: 1
	}
} as Moo.Rules;

const lexerState_LiteralString = {
	[TOKEN_KIND.NEWLINE]: { match: /\r?\n/, lineBreaks: true },
	[TOKEN_KIND.CHAR]: {
		match: /.+/,
		lineBreaks: false
	}
} as Moo.Rules;

/**
 * Moo configured tokens
 */
export const lexerStatesDefault = {
	main: lexerState_Main,
	string_template: lexerState_StringTemplate,
	literal_string: lexerState_LiteralString,
	escape: lexerState_Escape
};

export const lexerStatesStringTemplate = {
	string_template: lexerState_StringTemplate,
	main: lexerState_Main,
	literal_string: lexerState_LiteralString,
	escape: lexerState_Escape
};
