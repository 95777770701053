/**
 * Composed Chart HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import {
	ClassList,
	THAEComponentDefinition,
	THAEComponentReact,
	HAEComponentMainContext
} from "@hexio_io/hae-lib-components";

import { isValidObject } from "@hexio_io/hae-lib-shared";

import { HAEComponentComposedChart_Definition } from "./definition";
import { IRoundedRectangleSettings } from "@amcharts/amcharts5/.internal/core/render/RoundedRectangle";
import { createScrollbar } from "./createScrollbar";
import { createAxes } from "./createAxes";
import { createSeries } from "./createSeries";
import { createRanges } from "./createRanges";
import { createLegend } from "./createLegend";
import { HAEComponentComposedChart_State } from "./state";
import { AxisZoomEvent, useChart, useChartEvents } from "./hooks";
import { createCleanUpQueue, runCleanUp } from "../../Functions/chartHelpers";
import { IXYCursorSettings } from "@amcharts/amcharts5/.internal/charts/xy/XYCursor";
import { createSubScope } from "@hexio_io/hae-lib-blueprint";

import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_cs_CZ from "@amcharts/amcharts5/locales/cs_CZ";

const HAEComponentComposedChart_React: THAEComponentReact<typeof HAEComponentComposedChart_Definition> = ({
	state,
	componentInstance,
	reactComponentClassList
}) => {
	const componentMainContext = React.useContext(HAEComponentMainContext);
	const viewportRootElement = componentMainContext.viewport.getRootElement();

	const resolved = !!viewportRootElement && isValidObject(state);

	const {
		data: stateData,
		resolvedAxes,
		resolvedSeries,
		resolvedRanges,
		legend,
		horizontalScrollbar,
		animate,
		datetimeUtc,
		datetimeFormat,
		locale,
		settingsTimestamp,
		cursorBehavior
	} = resolved ? state : ({} as Partial<HAEComponentComposedChart_State>);

	const elementRef = React.useRef<HTMLDivElement>();

	const chartRef = React.useRef<am5xy.XYChart>();
	const scrollbarChartRef = React.useRef<am5xy.XYChart>();

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-composed-chart",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	/*const _rangeChangeHandler = eventEnabled() && componentInstance.eventEnabled.rangeChange ? (scope: IScope) => {
		return componentInstance.eventTriggers.rangeChange(scope);
	} : undefined;*/

	// Chart init

	const { rootRef, colorRef, colorValuesString, styleRef, styleValuesString } = useChart(
		resolved,
		elementRef,
		viewportRootElement,
		animate,
		datetimeUtc
	);

	const zoomEventHandler = React.useCallback((zoomState: AxisZoomEvent) => {
		if (componentInstance.eventEnabled.zoomChanged) {
			componentInstance.eventTriggers.zoomChanged((scope) => createSubScope(scope, {
				_event: zoomState
			}));
		}
	}, []);

	const { onAxisStart, onAxisEnd } = useChartEvents(resolvedAxes, zoomEventHandler, 500);

	// Setup

	React.useLayoutEffect(() => {
		if (!resolved || !rootRef.current) {
			return;
		}

		const root = rootRef.current;

		const cleanUpQueue = createCleanUpQueue(root);

		const { base, background } = colorRef.current;
		const { borderRadius, spacing } = styleRef.current;

		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				maxTooltipDistance: 0,
				layout: root.verticalLayout
				// background: am5.Rectangle.new(root, {
				// 	fill: getChartColor(),
				// 	fillOpacity: 1
				// })
			})
		);

		chartRef.current = chart;

		// Set locale
		switch(locale) {
			case 'en_US': {
				root.locale = am5locales_en_US;
				break;
			}
			case 'cs_CZ': {
				root.locale = am5locales_cs_CZ;
				break;
			}
		}

		// Define cursor

		const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
			behavior: cursorBehavior as IXYCursorSettings['behavior']
		}));

		cursor.lineY.set("visible", false);

		// Basic styling

		const roundedRectangleBaseSettings: IRoundedRectangleSettings = {
			cornerRadiusTR: borderRadius,
			cornerRadiusBR: borderRadius,
			cornerRadiusTL: borderRadius,
			cornerRadiusBL: borderRadius
		};

		/*chart.zoomOutButton.setAll({
			...controlBaseSettings,
			paddingLeft: 0,
			paddingTop: 0
		});*/

		const zoomOutButtonIcon = chart.zoomOutButton.get("icon");

		zoomOutButtonIcon.setAll({
			stroke: base
		});

		chart.zoomOutButton.set(
			"background",
			am5.RoundedRectangle.new(root, {
				...roundedRectangleBaseSettings,
				cursorOverStyle: "pointer",
				fill: background,
				fillOpacity: 1,
				stroke: base,
				strokeWidth: 1
			})
		);

		// Horizontal scrollbar & preview

		const { scrollbar, scrollbarChart } = createScrollbar(
			horizontalScrollbar,
			root,
			chart,
			colorRef.current,
			styleRef.current,
			roundedRectangleBaseSettings
		);

		if (scrollbarChart) {
			scrollbarChartRef.current = scrollbarChart;
		}

		// Axes

		const { chartAxes, scrollbarChartAxes } = createAxes(
			resolvedAxes,
			root,
			chart,
			datetimeFormat,
			colorRef.current,
			scrollbarChart
		);

		// Series

		createSeries(
			resolvedSeries,
			root,
			chart,
			chartAxes,
			colorRef.current,
			cleanUpQueue,
			scrollbarChart,
			scrollbarChartAxes,
			onAxisStart,
			onAxisEnd
		);

		// Scrollbar adjustments

		if (scrollbar) {
			chart.bottomAxesContainer.children.push(scrollbar);
		}

		// Ranges

		createRanges(
			resolvedRanges,
			root,
			chart,
			chartAxes,
			colorRef.current,
			roundedRectangleBaseSettings
		);

		// Legend

		createLegend(legend, root, chart, spacing);

		return () => {
			runCleanUp(cleanUpQueue);
		};
	}, [ resolved, colorValuesString, styleValuesString, settingsTimestamp, onAxisStart, onAxisEnd ]);

	// Data

	React.useLayoutEffect(() => {
		if (!resolved || !chartRef.current || !Array.isArray(stateData?.value)) {
			return;
		}

		const { value: data } = stateData;

		chartRef.current.series.each((item, index) => {
			item.data.setAll(data);
			//item.appear(1000, 100);

			const scrollbarChartSeries = scrollbarChartRef.current?.series.getIndex(index);

			if (scrollbarChartSeries) {
				scrollbarChartSeries.data.setAll(data);
			}

			const chartXAxis = item.get("xAxis");
			const chartYAxis = item.get("yAxis");

			if (chartXAxis.isType("CategoryAxis")) {
				const xKey = item.get("categoryXField");

				const axisData = Array.from(
					new Set([
						...data.map((dataItem) => dataItem[xKey])
					])
				).map((item) => ({ x: item }));

				chartXAxis.data.setAll(axisData);

				if (scrollbarChartSeries) {
					scrollbarChartSeries.get("xAxis").data.setAll(axisData);
				}
			}

			if (chartYAxis.isType("CategoryAxis")) {
				const yKey = item.get("categoryYField");

				const axisData = Array.from(
					new Set([
						...data.map((dataItem) => dataItem[yKey])
					])
				).map((item) => ({ y: item }));

				chartYAxis.data.setAll(axisData);

				if (scrollbarChartSeries) {
					scrollbarChartSeries.get("yAxis").data.setAll(axisData);
				}
			}
		});
	}, [ resolved, colorValuesString, styleValuesString, stateData, settingsTimestamp ]);

	if (!resolved) {
		return null;
	}

	return <div ref={elementRef} className={classList.toClassName()} />;
};

export const HAEComponentComposedChart: THAEComponentDefinition<typeof HAEComponentComposedChart_Definition> =
	{
		...HAEComponentComposedChart_Definition,
		reactComponent: HAEComponentComposedChart_React
	};
